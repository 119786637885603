import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`UI Kits Setup`}</h2>
    <p>{`Legion’s design library is organized into several projects. To begin your design journey, make sure you’ve been invited to the project file via the `}<a parentName="p" {...{
        "href": "https://forms.gle/vNvoJ5t6FDM7D1VD8"
      }}><strong parentName="a">{`Request Form`}</strong></a>{`. Once invited, explore the available libraries assigned to you and select the right design library that fits your project needs.`}</p>
    <p>{`Every great design starts with a solid foundation. Begin by linking your design foundation with the UI Kits to build a strong base for your product design. This ensures that all variables---such as color, typography, size, and margin---are connected to components seamlessly, forming the backbone of your product.`}</p>
    <p>{`Before moving forward with more detailed design development, ensure that all the variables in your foundation are properly integrated into the components. This allows for easier adjustments as needed, ensuring the design stays flexible to match the evolving needs of your brand.`}</p>
    <h3>{`1. Checking foundation color sync with UI Kit`}</h3>
    <p>{`Once your foundation is connected, your next step is to align your design with your brand’s identity by adjusting the colors in the foundation based on a predefined color palette. Think of this as setting the mood for your design story. Colors play a crucial role in telling the story of your brand and should maintain consistency with the overall brand image and product goals. To ensure flexibility, using variables for this step is highly recommended.`}</p>
    <div {...{
      "style": {
        "background": "#F2F4F7",
        "borderRadius": "2rem",
        "display": "flex",
        "gap": "1rem",
        "justifyContent": "center",
        "alignItems": "center",
        "padding": "1.5rem"
      }
    }}>{`
  `}<div parentName="div" {...{
        "style": {
          "display": "flex",
          "flexDirection": "column",
          "alignItems": "center"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/ui-kit-setup/ui-kit-setup-checking-foundation-color-sync-with-ui-kit-before.png",
          "style": {
            "borderRadius": "0.5rem",
            "marginBottom": "1rem"
          },
          "alt": "change visual ui from variable and push to plugin before"
        }}></img>{`
    `}<span parentName="div" {...{
          "style": {
            "background": "#F79009",
            "borderRadius": "5rem",
            "color": "#FFF",
            "fontSize": "1rem",
            "padding": "0 0.5rem"
          }
        }}>{`Before - Default UI Kit`}</span>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "style": {
          "display": "flex",
          "flexDirection": "column",
          "alignItems": "center"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/ui-kit-setup/ui-kit-setup-checking-foundation-color-sync-with-ui-kit-after.png",
          "style": {
            "borderRadius": "0.5rem",
            "marginBottom": "1rem"
          },
          "alt": "change visual ui from variable and push to plugin after"
        }}></img>{`
    `}<span parentName="div" {...{
          "style": {
            "background": "#12B76A",
            "borderRadius": "5rem",
            "color": "#FFF",
            "fontSize": "1rem",
            "padding": "0 0.5rem"
          }
        }}>{`After - Customized UI Kit`}</span>{`
  `}</div>
    </div>
    <h3>{`2. Checking Typography sync with Foundation`}</h3>
    <p>{`With your colors set, it’s time to refine your typography. Typography is the voice of your brand, and adopting font styles defined for your brand or product reinforces this identity. Consistency in typography not only enhances the visual appeal but also strengthens the connection between the design and the brand’s personality. As with colors, using variables to handle typography adjustments will make the process smoother and more adaptable.`}</p>
    <div {...{
      "style": {
        "background": "#F2F4F7",
        "borderRadius": "2rem",
        "display": "flex",
        "gap": "1rem",
        "justifyContent": "center",
        "alignItems": "center",
        "padding": "1.5rem"
      }
    }}>{`
  `}<div parentName="div" {...{
        "style": {
          "display": "flex",
          "flexDirection": "column",
          "alignItems": "center"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/ui-kit-setup/ui-kit-setup-checking-typography-sync-with-foundation-before.png",
          "style": {
            "borderRadius": "0.5rem",
            "marginBottom": "1rem"
          },
          "alt": "checking typography sync with foundation before"
        }}></img>{`
    `}<span parentName="div" {...{
          "style": {
            "background": "#F79009",
            "borderRadius": "5rem",
            "color": "#FFF",
            "fontSize": "1rem",
            "padding": "0 0.5rem"
          }
        }}>{`Before - Default UI Kit`}</span>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "style": {
          "display": "flex",
          "flexDirection": "column",
          "alignItems": "center"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/ui-kit-setup/ui-kit-setup-checking-typography-sync-with-foundation-after.png",
          "style": {
            "borderRadius": "0.5rem",
            "marginBottom": "1rem"
          },
          "alt": "checking typography sync with foundation after"
        }}></img>{`
    `}<span parentName="div" {...{
          "style": {
            "background": "#12B76A",
            "borderRadius": "5rem",
            "color": "#FFF",
            "fontSize": "1rem",
            "padding": "0 0.5rem"
          }
        }}>{`After - Customized UI Kit`}</span>{`
  `}</div>
    </div>
    <h3>{`3. Customizing visual UI with your brand style`}</h3>
    <p>{`Design doesn’t stop at colors and typography, padding, margins, and other properties are keyplayers in ensuring that your product design aligns perfectly with the UI Kits. By applying these properties, you complete the design’s story, ensuring everything comes together cohesively. AGain, be sure to use variables to handle these adjustments efficiently.`}</p>
    <div {...{
      "style": {
        "background": "#F2F4F7",
        "borderRadius": "2rem",
        "display": "flex",
        "gap": "1rem",
        "justifyContent": "center",
        "alignItems": "center",
        "padding": "1.5rem"
      }
    }}>{`
  `}<div parentName="div" {...{
        "style": {
          "display": "flex",
          "flexDirection": "column",
          "alignItems": "center",
          "width": "400px"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/ui-kit-setup/ui-kit-setup-customizing-visual-ui-with-your-brand-style-before.png",
          "style": {
            "borderRadius": "0.5rem",
            "marginBottom": "1rem"
          },
          "alt": "customizing visual ui with your brand style"
        }}></img>{`
    `}<span parentName="div" {...{
          "style": {
            "background": "#F79009",
            "borderRadius": "5rem",
            "color": "#FFF",
            "fontSize": "1rem",
            "padding": "0 0.5rem"
          }
        }}>{`Before - Default UI Kit`}</span>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "style": {
          "display": "flex",
          "flexDirection": "column",
          "alignItems": "center",
          "width": "400px"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/ui-kit-setup/ui-kit-setup-customizing-visual-ui-with-your-brand-style-after.png",
          "style": {
            "borderRadius": "0.5rem",
            "marginBottom": "1rem"
          },
          "alt": "customizing visual ui with your brand style"
        }}></img>{`
    `}<span parentName="div" {...{
          "style": {
            "background": "#12B76A",
            "borderRadius": "5rem",
            "color": "#FFF",
            "fontSize": "1rem",
            "padding": "0 0.5rem"
          }
        }}>{`After - Customized UI Kit`}</span>{`
  `}</div>
    </div>
    <h3>{`4. Final checking and Publish`}</h3>
    <p>{`Before your UI Kit becomes a library that can be published, perform a final check. Ensure that all elements—colors, typography, and other properties—are connected, aligned with your brand, and meet the necessary standards. This is the moment to make final adjustments and polish your design until it’s just right.`}</p>
    <p>{`Once everything is aligned, you’re ready to publish the UI Kit as a library, making it accessible and reusable for future designs. `}</p>
    <div {...{
      "style": {
        "background": "#F2F4F7",
        "borderRadius": "2rem",
        "display": "grid",
        "gridTemplateColumns": "repeat(2, 1fr)",
        "gap": "1rem",
        "justifyContent": "center",
        "alignItems": "center",
        "padding": "1.5rem"
      }
    }}>{`
  `}<div parentName="div" {...{
        "style": {
          "display": "flex",
          "flexDirection": "column",
          "alignItems": "center"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/ui-kit-setup/ui-kit-setup-final-checking-and-publish-step-one.png",
          "style": {
            "borderRadius": "0.5rem",
            "marginBottom": "1rem"
          },
          "alt": "final checking and publishing step one"
        }}></img>{`
    `}<span parentName="div" {...{
          "style": {
            "display": "block",
            "textAlign": "center"
          }
        }}>{`Step 1 - Select Library and click publish changes`}</span>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "style": {
          "display": "flex",
          "flexDirection": "column",
          "alignItems": "center"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/ui-kit-setup/ui-kit-setup-final-checking-and-publish-step-two.png",
          "style": {
            "borderRadius": "0.5rem",
            "marginBottom": "1rem"
          },
          "alt": "final checking and publishing step two"
        }}></img>{`
    `}<span parentName="div" {...{
          "style": {
            "display": "block",
            "textAlign": "center"
          }
        }}>{`Step 2 - Enter description update or release notes`}</span>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "style": {
          "display": "flex",
          "flexDirection": "column",
          "alignItems": "center"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/ui-kit-setup/ui-kit-setup-final-checking-and-publish-step-three.png",
          "style": {
            "borderRadius": "0.5rem",
            "marginBottom": "1rem"
          },
          "alt": "final checking and publishing step three"
        }}></img>{`
    `}<span parentName="div" {...{
          "style": {
            "display": "block",
            "textAlign": "center"
          }
        }}>{`Step 3 - Click publish button and wait the process`}</span>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "style": {
          "display": "flex",
          "flexDirection": "column",
          "alignItems": "center"
        }
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/ui-kit-setup/ui-kit-setup-final-checking-and-publish-step-four.png",
          "style": {
            "borderRadius": "0.5rem",
            "marginBottom": "1rem"
          },
          "alt": "final checking and publishing step four"
        }}></img>{`
    `}<span parentName="div" {...{
          "style": {
            "display": "block",
            "textAlign": "center"
          }
        }}>{`Step 4 - Complete Publishing or view the status`}</span>{`
  `}</div>
    </div>
    <h2>{`Connect to your component code`}</h2>
    <p>{`Legion UI Kit v3 has enabled us to integrate real-time style changing to component’s code with different theme style, you can follow these steps to integrate this to your UI Kit.`}</p>
    <h3>{`1. Request to register your theme style to the UI Kit`}</h3>
    <p>{`When you have finished setting up your UI Kit, you need to register the UI Kit product style/theme in Figma to the Legion team to be integrated into the component’s code in order to have a theme library that is already in sync with the design and can be used by developers to start the development process.`}</p>
    <p>{`The first step that needs to be taken is: Submit a post on the Legion Discord server, write new topic under `}<inlineCode parentName="p">{`#issue-report-design`}</inlineCode>{` text-channel, follow the request format as shown in the image below and apply `}<inlineCode parentName="p">{`Foundation`}</inlineCode>{` tag. After that, wait within `}<strong parentName="p">{`24 hours`}</strong>{` for the Legion team to provide access to your requesting email address and add the registered theme to the Legion theme library.`}</p>
    <div {...{
      "style": {
        "background": "#F2F4F7",
        "borderRadius": "2rem"
      }
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/ui-kit-setup/ui-kit-setup-request-to-register-your-theme-style-to-the-ui-kit.png",
        "style": {
          "borderRadius": "1.5rem",
          "padding": "1.5rem"
        },
        "alt": "request to register your theme style to the ui kit"
      }}></img>
    </div>
    <h3>{`2. Open and login to Legion Tokens Manager (Figma Plugin)`}</h3>
    <p>{`After getting access from email, please reopen the UI Kit on Figma, then find and open the Legion Tokens Manager plugin then login by entering the Theme Code and access token given in t he email into the plugin, after successfully logging in then click the push to repository button to update the changes made to the UI Kit into the asset code, wait a few moment until the successful information appears. In case it failed, please close and push again or wait a few minutes then press the `}<inlineCode parentName="p">{`Push to Repository`}</inlineCode>{` button again.`}</p>
    <p>{`To see a preview or suitability of the UI Kit in Figma with the asset code that the develop will use, you can open Legion’s Storybook then on the top panel, select the theme you wish to preview and already registeredm, then please see the visuals or interactions of each component on the left panel of the Storybook or you can also see the variations or component variants via the control panel at the bottom of the Storybook panel.`}</p>
    <div {...{
      "style": {
        "background": "#F2F4F7",
        "borderRadius": "2rem"
      }
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/ui-kit-setup/ui-kit-setup-open-and-login-to-legion-tokens-manager.png",
        "style": {
          "borderRadius": "1.5rem",
          "padding": "1.5rem"
        },
        "alt": "open and login to legion tokens manager, it's a figma plugin"
      }}></img>
    </div>
    <h3>{`3. Change visual UI from variable and push to plugin`}</h3>
    <p>{`Every time you make once or more changes or updates to the foundation and comopnents in the UI Kit through the Figma variable, you always need to update the asset code by doing the steps in section above, and don’t forget that after pushing, you also need to check its suitability in the Storybook to keep the asset code in sync in order to maintain pixel perfect suitability with development. A brief guide to the workflow in updating the appearance of the UI Kit can be seen in the video below. `}</p>
    <div {...{
      "style": {
        "background": "#F2F4F7",
        "borderRadius": "2rem"
      }
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/guideline/ui-kit-setup/ui-kit-setup-change-visual-ui-froim-variable-and-plush-to-plugin.png",
        "style": {
          "borderRadius": "1.5rem",
          "padding": "1.5rem"
        },
        "alt": "change visual ui from variable and push to plugin"
      }}></img>
    </div>
    <h2>{`Recommended Figma Plugins`}</h2>
    <p>{`Some recommended plugins to simplify your workflow, task, accessibility checking, and design quality:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/1377553084101217769/legion-tokens-manager"
        }}><strong parentName="a">{`Legion Tokens Manager`}</strong></a>{`: A tool to manage design tokens like colors and fonts, ensuring consistency across projects.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/929607085343688745/color-shades"
        }}><strong parentName="a">{`Color Shades`}</strong></a>{`: A tool to createa and adjust color shades for a consistent and harmonious design.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/1298692189910272274/local-print"
        }}><strong parentName="a">{`Local Print`}</strong></a>{`: A feature to quickly print design assets for reviews or presentations.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/931280467863251825/adee-comprehensive-accessibility-tool"
        }}><strong parentName="a">{`Adee Comprehensive Accessibility Tool`}</strong></a>{`: Test color contrast, simulate 8 color blind simulations, and test touch target sizes to ensure they meet the various device standards.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/732603254453395948/stark-contrast-accessibility-tools"
        }}><strong parentName="a">{`Stark - Contrast & Accessibility Tools`}</strong></a>{`: Contrast Checker, Focus Order, Alt-Text Annotations, Vision Simulator, and more, all in one place.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/798826066406007173/ditto-manage-copy-from-design-to-production"
        }}><strong parentName="a">{`Ditto`}</strong></a>{`: Single source of truth for everyone touching copy, from writers to designers to engineers.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      